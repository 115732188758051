<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard
          title="Reclamos"
          :subtitle="`${buyer.reclamos.length} reclamos ingresados`"
        ></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="buyer.reclamos"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No hay encuestas ingresados"
          :loading="loading"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td v-html="props.item.codigo"></td>
              <td v-html="props.item.nombre"></td>
              <td v-html="props.item.desde"></td>
              <td v-html="props.item.hasta"></td>
              <td
                v-html="
                  `${props.item.monto}${
                    !props.item.tipo ? '%' : ' de descuento'
                  }`
                "
              ></td>
              <td>
                <v-icon
                  v-if="
                    (user.role.id === 1 || user.role.id === 2) &&
                      user.shop.admin
                  "
                  small
                  class="mr-2"
                  @click="editItem(props.item)"
                >
                  edit
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'BuyerSii',
  components: { titleCard },
  props: ['buyer'],
  data: () => ({
    headers: [
      { text: 'Codigo', align: 'left', sortable: false },
      { text: 'Descripcion', align: 'left', sortable: false },
      { text: 'Desde', align: 'left', sortable: false },
      { text: 'Hasta', align: 'left', sortable: false },
      { text: 'Monto', align: 'left', sortable: false },
      { text: '', align: 'left', sortable: false }
    ],
    pagination: { rowsPerPage: 50 },
    rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    editItem(item) {
      console.log('Editar', item)
    },
    deleteItem(item) {
      console.log('Eliminar', item)
    }
  }
}
</script>

<style></style>
